import { defineStore } from 'pinia'
import { useStorage } from '@vueuse/core'

const STORAGE_KEY = 'auth'

type AUTH_USER = {
  id?: number
  role?: {
    key: string
  }
}

export const useAuthStore = defineStore('auth', () => {
  const user = useStorage<AUTH_USER>(`${STORAGE_KEY}.user`, {})
  const token = useStorage(`${STORAGE_KEY}.token`, '')

  const isLoggedIn = computed(() => {
    return token.value.length > 0 && user.value.id && user.value.id > 0
  })

  const isAdmin = computed(() => {
    return user.value.role?.key === 'admin'
  })

  /**
   * Clear user's auth info in the browser
   */
  const onClearAuth = () => {
    user.value = {}
    token.value = ''
  }

  /**
   * Store user's auth info in local storage
   */
  const onStoreAuth = (authUser: object, authToken: string) => {
    user.value = authUser
    token.value = authToken
  }

  return {
    isAdmin,
    isLoggedIn,
    onClearAuth,
    onStoreAuth,
    token,
    user,
  }
})
