import axios, {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse,
  InternalAxiosRequestConfig,
  isAxiosError,
} from 'axios'
import { sentence } from 'to-case'
import { useAuthStore } from '@/auth/auth.store'

export const apiUrl = import.meta.env.VITE_API_URL

export interface ApiResponse {}

const log = (message: string, payload?: any) => {
  if (import.meta.env.MODE === 'development') {
    console.log(message, payload)
  }
}

const setupApi = () => {
  const http = axios.create({
    baseURL: import.meta.env.VITE_API_URL,
    timeout: 30000,
  })
  http.interceptors.request.use(
    (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
      const { headers } = config
      // log(`🚀 [API] ${method?.toUpperCase()} ${url} | Request`)
      const $auth = useAuthStore()
      if ($auth.token) {
        headers['Authorization'] = `Bearer ${$auth.token}`
      }
      return config
    },
  )
  http.interceptors.response.use(
    (response: AxiosResponse): AxiosResponse => {
      const { method, url } = response.config
      const { status, data } = response
      log(`🚀 [API] ${method?.toUpperCase()} ${url} | Response ${status}`, data)
      return data
    },
    /**
     * Return the error response and a simple error message that can be displayed to the user
     * @param error
     * @returns object
     */
    (error: AxiosError | Error): Promise<AxiosError> => {
      if (isAxiosError(error) && error.response?.data) {
        const { message } = error
        const { method, url } = error.config as AxiosRequestConfig
        const { status, data } = (error.response as AxiosResponse) ?? {}
        const { errors } = data
        log(
          `🚨 [API] ${method?.toUpperCase()} ${url} | Error ${status} ${message}`,
          data,
        )
        let displayMessage = ''
        switch (status) {
          // If failed validation, message should be the first error
          case 422:
            // Assuming adonis response
            const _error = errors && errors[0]
            if (_error) {
              displayMessage = sentence(
                `${_error.message} for field: ${_error.field}`,
              )
            }
            break
          default:
            displayMessage = errors
        }
        // Return message and response
        return Promise.reject({
          message: displayMessage,
          response: error.response,
        })
      } else {
        log(`🚨 [API] | Error ${error.message}`, error)
        return Promise.reject(error)
      }
    },
  )
  return http
}

export default setupApi()
