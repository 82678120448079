<script lang="ts" setup>
import App84Wrapper from '@/app/components/App84Wrapper.vue'
import { useAuthStore } from '@/auth/auth.store'

const $auth = useAuthStore()
/**
 * There is no landing page yet for Academy.
 * Show user dashboard page if user is logged in.
 * Otherwise, redirect to login
 */

// Displays app landing page
// Redirect to dashboard if user is logged in
// @todo: Make base domain display either instead of redirecting
// const isLoaded = ref(false)
const router = useRouter()
onMounted(() => {
  if ($auth.isLoggedIn) {
    router.push('/dashboard')
    return
  }
  // No landing page yet, redirect to login
  router.push('/login')
  // isLoaded.value = true
})
</script>

<template>
  <!--  <div-->
  <!--    v-if="isLoaded"-->
  <!--    class="max-w-sm text-center mx-auto my-24 flex flex-col gap-4"-->
  <!--  >-->
  <!--    <div>84 Starter Kit</div>-->
  <!--    <div>This should be a landing page with marketing and etc...</div>-->
  <!--    <kit-button primary to="/login">Login</kit-button>-->
  <!--  </div>-->
  <!-- <App84Wrapper /> -->
</template>

<route lang="yaml">
meta:
  layout: BlankLayout.vue
</route>
