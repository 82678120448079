<script setup lang="ts">
import {
  appUnsavedChanges,
  appUnsavedChangesConfirmNavigation,
} from '@/app/app.composables'
import { useAuthStore } from '@/auth/auth.store'
import api from './api'

const $auth = useAuthStore()
const router = useRouter()
/**
 * If user confirms, clear unsaved changes and call the navigation
 */
const onConfirm = () => {
  appUnsavedChanges.value = false
  appUnsavedChangesConfirmNavigation.value()
  appUnsavedChangesConfirmNavigation.value = null
}
/**
 * User canceled the navigation, clear navigation which will hide the modal
 */
const onClose = () => {
  appUnsavedChangesConfirmNavigation.value = null
}
const authCheck = () => {
  if (
    window.location.pathname !== '/login' &&
    window.location.pathname !== '/callback' &&
    !$auth.isLoggedIn
  ) {
    $auth.onClearAuth()
    router.push('/login')
  }
}
onMounted(async () => {
  authCheck()
  const response = await api.get('auth')
  console.log('AUTH RESPONSE', response)
})
</script>

<template>
  <router-view />
  <kit-modal-confirm
    v-if="appUnsavedChangesConfirmNavigation"
    icon="warning"
    title="Leave the page?"
    @confirm="onConfirm"
    @close="onClose"
  >
    <div>You have unsaved changes.</div>
  </kit-modal-confirm>
</template>

<style></style>
