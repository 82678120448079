import { createApp } from 'vue'
import { Kit } from 'kit'
import kitConfig from './kit.config'
import { createPinia } from 'pinia'
import { setupLayouts } from 'virtual:generated-layouts'
import generatedRoutes from 'virtual:generated-pages'
import App from '@/app/App.vue'
// Import kit's npm stylesheet if not in local mode
import(
  import.meta.env.VITE_KIT_MODE === 'local'
    ? '@/app/styles/styles.local'
    : '@/app/styles/styles.npm'
)
import { createRouter, createWebHistory } from 'vue-router'

const routes = setupLayouts(generatedRoutes)
const router = createRouter({
  history: createWebHistory(),
  routes,
})
const pinia = createPinia()
createApp(App).use(router).use(pinia).use(Kit, kitConfig).mount('#app')
