// @unocss-include
const kitConfig = {
  icons: {
    dice: 'i-fad:random-2dice',
    journey: 'i-mdi-map-outline',
    course: 'i-mdi-school-outline',
    subject: 'i-carbon-book',
    text: 'i-carbon-text-font',
    topic: 'i-carbon-document',
    quiz: 'i-mdi-clipboard-text-outline',
    remove: 'i-dashicons:remove',
  },
}

export default kitConfig
